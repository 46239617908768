import styled from 'styled-components';

export const BgWrapper = styled.div`
  background-image: url(${props => props.bg});
  background-repeat: repeat-y;
  background-color: ${props => props.color ? props.color : 'white'};
  background-size: cover;
  background-position: ${props => props.position ? props.position : 'center'};
`

export const GreyBox = styled.div`
  background-color: #F4F5FA;
  border-radius: 15px;
  padding: 1.5rem 5rem 1.5rem 5rem;

  @media screen and (max-width: 767px) {
    padding: 1.5rem 2rem 1.5rem 2rem;
  }
`

export const CategoryWrapper = styled.div`
  background-color: #F4F5FA;
  border-radius: 15px;
  width: 100%;
  padding: 1rem 1rem 1rem 1rem;
`

export const CategoryCardWrapper = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 0px 30px rgba(51, 1, 109, 0.1);
  border-radius: 8px;
  height: 228px;
  max-width: 280px;
  min-width: 250px;
  padding: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; 
`