import PropTypes from 'prop-types';
import React from 'react';
import StoreIcons from './store-icons';
import styled from 'styled-components';

const StoreIconsWrapper = styled.div`
	background: #ffffff;
	border-radius: 15px;
	box-shadow: 0px 0px 36px rgba(17, 41, 70, 0.25);
	padding: 40px;
`;

DownloadAppBanner.propTypes = {
	className: PropTypes.string,
	appStoreLink: PropTypes.string,
	googlePlayLink: PropTypes.string,
	title: PropTypes.string
};

DownloadAppBanner.defaultProps = {
	title: 'Свали NetSurf Play от:'
};

function DownloadAppBanner({ className, appStoreLink, googlePlayLink, title }) {
	return (
		<StoreIconsWrapper className={className}>
			<h2>{title}</h2>
			<StoreIcons appStoreLink={appStoreLink} googlePlayLink={googlePlayLink} />
		</StoreIconsWrapper>
	);
}

export default DownloadAppBanner;
