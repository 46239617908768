import '../styles/common.css';

import React, { useEffect, useRef, useState } from 'react';

import { BgWrapper } from '../components/shared/components';
import DownloadAppBanner from '../components/shared/download-app-banner';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../components/shared/section-titles';
import { TitleH1 } from './magazini';
import constants from '../constants/netsurf-play';
import { graphql } from 'gatsby';
import meta from '../constants/meta-descriptions';

export default ({ data }) => {
	const [screenSize, setScreenSize] = useState(0);

	let features = [];
	constants.features.map((feat) => {
		features.push({
			image: feat.image,
			title: feat.title,
			text: feat.text
		});
	});

	useEffect(() => {
		checkScreenSize();
		window.addEventListener('resize', checkScreenSize);
		return () => {
			window.removeEventListener('resize', checkScreenSize);
		};
	}, []);

	const checkScreenSize = () => {
		setScreenSize(window.innerWidth);
	};

	const appStoreLink = 'https://apps.apple.com/bg/app/netsurf-play/id1200797767';
	const googlePlayLink =
		'https://play.google.com/store/apps/details?id=io.bits84.netsurfplay';

	return (
		<>
			<BgWrapper bg={data.bg.childImageSharp.fluid.src} position={'bottom'}>
				<Layout>
					<SEO
						title={meta.netsurfplay.title}
						description={meta.netsurfplay.metaDescription}
						image={data.heroImg.childImageSharp.fluid.src}
					/>

					<div style={{ paddingTop: '8rem' }}>
						<div className="d-none d-lg-block play-container">
							<Img
								imgStyle={{ height: 400, objectFit: 'fill' }}
								fluid={data.heroImg.childImageSharp.fluid}
							/>
						</div>
						<div
							className="d-lg-none play-container"
							style={{ paddingBottom: '5rem' }}
						>
							<Img
								objectFit="cover"
								fluid={data.heroImg.childImageSharp.fluid}
							/>
						</div>
						<div>
							<SectionTitle undertext="Гледай любимата си телевизия в реално време и си плащай сметките с един „цък”.">
								<TitleH1>
									NetSurf Play:
									<br />
									Удобство и забавление в една човешка длан
								</TitleH1>
								<hr className="center" />
							</SectionTitle>
						</div>

						<BgWrapper
							bg={data.videoBg.childImageSharp.fluid.src}
							color={'transparent'}
						>
							<div className="position-relative row d-flex justify-content-center w-100">
								{/* <div className="d-none d-lg-block video-container">
									<Img
										fluid={data.videoBanner.childImageSharp.fluid}
										objectFit="contain"
									/>
								</div> */}
								<div className="video-wrap">
									<iframe
										width="700"
										height="360"
										src="https://www.youtube.com/embed/qDJqvGdN5IQ"
										frameBorder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowFullScreen
										title="NetSurf - GPON"
										style={{ maxWidth: '100%' }}
									></iframe>
								</div>
							</div>
						</BgWrapper>
						<div className="row d-flex justify-content-center my-5">
							<div className="col-12 col-lg-11 col-xl-9 row d-flex justify-content-center">
								{features.map(({ image, title, text }, index) => (
									<div
										className="col-12 col-sm-6 col-lg-4 text-center px-md-5 my-3 my-sm-5"
										key={index}
									>
										<ScrollAnimation
											animateIn="fadeIn"
											animateOnce={true}
										>
											<div
												className="d-flex justify-content-center align-items-center"
												style={{ minHeight: '100px' }}
											>
												<img
													src={image}
													className="img-fluid"
													alt={title}
												/>
											</div>
											<div className="px-sm-4">
												<h3>{title}</h3>
												<p>{text}</p>
											</div>
										</ScrollAnimation>
									</div>
								))}
							</div>
						</div>

						<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
							<div className="container d-flex justify-content-center align-items-center mb-5">
								<DownloadAppBanner
									className="text-center mt-5"
									googlePlayLink={googlePlayLink}
									appStoreLink={appStoreLink}
								/>
							</div>
						</ScrollAnimation>
					</div>
				</Layout>
			</BgWrapper>
		</>
	);
};

export const query = graphql`
	query NetSurfPlay {
		wordpressPage(slug: { eq: "netsurf-play" }) {
			acf {
				features
			}
		}
		bg: file(relativePath: { eq: "netsurfplay/bg.png" }) {
			childImageSharp {
				fluid(maxWidth: 2000) {
					src
				}
			}
		}
		heroImg: file(relativePath: { eq: "netsurfplay/hero.webp" }) {
			childImageSharp {
				fluid(maxHeight: 400) {
					...GatsbyImageSharpFluid
				}
			}
		}
		videoBanner: file(relativePath: { eq: "netsurfplay/video-banner.webp" }) {
			childImageSharp {
				fluid(maxHeight: 700) {
					...GatsbyImageSharpFluid
				}
			}
		}
		videoBg: file(relativePath: { eq: "netsurfplay/video-bg.png" }) {
			childImageSharp {
				fluid(maxWidth: 2000) {
					src
				}
			}
		}
	}
`;

