import React from 'react';
import googlePlayIcon from '../../images/icons/google-play.png';
import appleStoreIcon from '../../images/icons/app-store.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default ({ appStoreLink, googlePlayLink }) => (
	<div className="row d-flex justify-content-center">
		{appStoreLink && (
			<div className="col-12 col-lg-5 d-inline-block d-flex justify-content-center p-2 mt-2 mt-lg-0">
				<a
					className=""
					href={appStoreLink}
					target="_blank"
					rel="noopener noreferrer"
				>
					<LazyLoadImage
						src={appleStoreIcon}
						className="img-fluid store-icon"
						alt="Apple Store"
					/>
				</a>
			</div>
		)}

		{googlePlayLink && (
			<div className="col-12 col-lg-5 d-inline-block d-flex justify-content-center p-2 mt-2 mt-lg-0">
				<a
					className=""
					href={googlePlayLink}
					target="_blank"
					rel="noopener noreferrer"
				>
					<LazyLoadImage
						src={googlePlayIcon}
						className="img-fluid store-icon"
						alt="Google Play"
					/>
				</a>
			</div>
		)}
	</div>
);
