import chat from '../images/netsurfplay/chat.png';
import control from '../images/netsurfplay/control.png';
import epay from '../images/netsurfplay/epay.png';
import extras from '../images/netsurfplay/extrasandpackages.png';
import livetv from '../images/netsurfplay/livetv.png';
import pause from '../images/netsurfplay/pause.png';
import programme from '../images/netsurfplay/programme.png';
import promo from '../images/netsurfplay/promo.png';
import settings from '../images/netsurfplay/settings.png';

export default {
	features: [
		{
			image: extras,
			title: 'Избор от Пакети',
			text:
				'Нашият спектър от пакети - Филмов, Спортен, Златен стандарт и от А до Я, ти позволява да се гмурнеш в морето от телевизионни предавания, филми и спортни събития, чрез премиум канали като HBO, Diema Xtra, MAX Sport. Също така може да грабнеш безплатния код за регистрация в HBO MAX, с който стоенето пред телевизора или телефона не е просто действие, а емоция.'
		},
		{
			image: livetv,
			title: 'Гледай любимите си предавания онлайн на живо',
			text:
				'Net Surf комбинира удобство и иновативност, позволявайки да гледаш по всяко време нашата богата гама от канали на мобилния си телефон, таблет или лаптоп, навсякъде с теб, независимо къде се намираш.'
		},
		{
			image: control,
			title: 'Контролирай профила си',
			text:
				'Чрез приложението на NetSurf ще спестиш време и усилия, като собственоръчно провериш информация за услугата си или пуснеш заявка за повреда. Същевременно, след като си генерираш код от Netsurf Play, с който да се  регистрираш във NetSurf Assistant на android устройство, сам ще правиш диагностика на услугата си.'
		},
		{
			image: settings,
			title: 'Моят интернет (Настройки)',
			text:
				'NetSurf Play ти позволява да промениш настройките на своя оптичен рутер с лекота.'
		},
		{
			image: epay,
			title: 'Плащане',
			text:
				'Чудиш се колко време ти остава до следващата сметка, как и къде да платиш? Или просто ти е нужна информация за предишна фактура? Можеш да получиш отговорите на тези въпроси бързо и лесно, само с един клик.'
		},
		{
			image: pause,
			title: 'Забавлявай се заедно с любимите хора',
			text:
				'Вече можеш да споделяш цифрово съдържание с приятели. NetSurf Play ти позволява да генерираш допълнителен код, който да регистрираш на друго устройство или да го предоставиш на свой приятел, дори да не е наш клиент, за да се наслаждавате заедно  на изобилието от телевизионни програми'
		}
	]
};

