import React from 'react';


const SectionTitles = (props) => (
  <div className='row w-100 section-title'>
    <div className={'row d-flex justify-content-center align-items-center w-100 ' + (props.left ? 'text-left' : 'text-center')} >
      <div className='col-12 col-md-7 col-lg-12'>
        {props.children}
      </div>
    </div>
    {props.undertext && (
      <div className={'row d-flex justify-content-center align-items-center text-center w-100 ' + (props.left ? 'text-left' : 'text-center')}>
        <div className='col-12 col-md-9 col-lg-7 col-xl-6'><p>{props.undertext}</p></div>
      </div>
    )}
  </div>
)

export default SectionTitles;